import React from "react";
import "./style.css";
import rolling from "../../img/rolling.jpeg";
import kanagi from "../../img/kanagi.jpg";
import rollingShutters from "../../img/rolling_shutters.jpeg";
import ssrailing from "../../img/ss_railing.jpeg";
import kichentrolly from "../../img/kichen_trolly.jpeg";
import ssrailing1 from "../../img/ss_railing1.jpeg";
import stainless from "../../img/stainless.jpeg";
import steelrailing from "../../img/steelRailing.jpeg";
import home from "../../img/home.jpg";

const Banner = () => {
  return (
    
    <div classname="banner">
  <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
    <div className="carousel-inner">
      <div className="carousel-item active">
        {/* <img src={home} className="d-block w-100 img-fluid" alt="..." /> */}
        <img src={ssrailing} className="d-block w-100 img-fluid" alt="..." />
      </div>

      <div className="carousel-item">
        <img src={rolling} className="d-block w-100 img-fluid" alt="..." />
      </div>

      <div className="carousel-item">
        <img src={rollingShutters} className="d-block w-100 img-fluid" alt="..." />
      </div>

      <div className="carousel-item">
        <img src={home} className="d-block w-100 img-fluid" alt="..." />
        </div>

      <div className="carousel-item">
        <img src={kichentrolly} className="d-block w-100 img-fluid" alt="..." />
      </div>

      <div className="carousel-item">
        <img src={ssrailing1} className="d-block w-100 img-fluid" alt="..." />
      </div>

      <div className="carousel-item">
        <img src={stainless} className="d-block w-100 img-fluid" alt="..." />
      </div>

      <div className="carousel-item">
        <img src={steelrailing} className="d-block w-100 img-fluid" alt="..." />
      </div>

      <div className="carousel-item">
        <img src={kanagi} className="d-block w-100 img-fluid" alt="..." />
      </div>
      
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true" />
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true" />
      <span className="visually-hidden">Next</span>
    </button>
  </div>
</div>

  );
};

export default Banner;
