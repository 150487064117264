import React,{ useEffect} from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Contact from "./pages/Contact/Contact";
import AboutUs from "./pages/Aboutus/AboutUs";
import Gallery from "./pages/gallery/gallery";
import OurWork from "./pages/ourWork/OurWork";
import Faq from "./pages/Faq/Faq";

import ProductDetails from "./components/productDetails/ProductDetails";
import SteelRailing from "./components/steelRailing/SteelRailing";
import RollingShutters from "./components/rollingShutters/RollingShutters";
import SsRailing from "./components/ssRailing/SsRailing";

import Aos from "aos";
import "aos/dist/aos.css";
import Blog from "./pages/Faq/Blog/Blog";
import Blogview from "./pages/BlogView/Blogview";
import gallery from "./pages/gallery/gallery";

const App = () => {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/ourwork" element={<OurWork />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/productDetails" element={<ProductDetails />} />
        <Route path="/steelRailing" element={<SteelRailing />} />
        <Route path="/rollingShutters" element={<RollingShutters />} />
        <Route path="/ssRailing" element={<SsRailing />} />
        
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogview" element={<Blogview />} />

      </Routes>
    </>
  );
};

export default App;
