import React, { Component } from "react";
import Swiper from "swiper";
import "./style.css";
import testimonials1 from "../../img/testimonials/testimonials-1.jpg";
import testimonials2 from "../../img/testimonials/testimonials-2.jpg";
import testimonials3 from "../../img/testimonials/testimonials-3.jpg";
import testimonials4 from "../../img/testimonials/testimonials-4.jpg";
import testimonials5 from "../../img/testimonials/testimonials-5.jpg";


import Slider from "react-slick";

const Testinomial = () => {
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    //    };
    return(
        <>
        {/* <h2> Single Item</h2> */}
        <h1 style={{textAlign:'center',color:'rgb(2, 47, 131)'}}>TESTIMONIALS</h1>
        <div class="container mt-5 mb-10 d-flex justify-content-center ">
         
    <div class="row">
        <div class="col-md-4">
            <div class="maincard p-3">
                <div class="thecard">
                    <div class="thefront text-center py-4">
                    <div className="testCircle"><img src={testimonials4} class="img-fluid test-img" ></img></div>
                        <h3 class="mt-2 mb-0">Haribhau Galve</h3> <small class="user_name">Software developer</small>
                    </div>
                    <div class="theback py-3 px-3">
                        <div class="quote mt-3">
                            <h4><i class="fa fa-quote-left"></i></h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="maincard p-3">
                <div class="thecard">
                    <div class="thefront text-center py-4">  <div className="testCircle"><img src={testimonials2} class="img-fluid test-img" ></img></div>
                        <h3 class="mt-2 mb-0">Name:</h3> <small class="user_name">Sales executive</small>
                    </div>
                    <div class="theback py-3 px-3">
                        <div class="quote mt-3">
                            <h4><i class="fa fa-quote-left"></i></h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="maincard p-3">
                <div class="thecard">
                    <div class="thefront text-center py-4">  <div className="testCircle"><img src={testimonials3} class="img-fluid test-img" ></img></div>
                        <h3 class="mt-2 mb-0">ShitalKumar galve</h3> <small class="user_name">Sales Manager</small>
                    </div>
                    <div class="theback py-3 px-3">
                        <div class="quote mt-3">
                            <h4><i class="fa fa-quote-left"></i></h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
      
        </>

    )
}

export default Testinomial;

// export class Testinomial extends Component {
//     componentDidMount() {
//         new Swiper('.testimonials-slider', {
//             speed: 600,
//             loop: true,
//             autoplay: {
//               delay: 5000,
//               disableOnInteraction: false
//             },
//             slidesPerView: 'auto',
//             pagination: {
//               el: '.swiper-pagination',
//               type: 'bullets',
//               clickable: true
//             },
//             breakpoints: {
//               320: {
//                 slidesPerView: 1,
//                 spaceBetween: 20
//               },
//               1200: {
//                 slidesPerView: 3,
//                 spaceBetween: 20
//               }
//             }
//           });
//     }
//     render() {
//         return (
//             <div>
//                 <div>
//                     <section id="testimonials" className="testimonials">
//                         <div className="container" data-aos="fade-up">
//                             <header className="section-header pb-5">
//                                 <h2 className="text-center text-primary fw-bold display-2 ">
//                                     Testimonials
//                                 </h2>
//                                 <p className="text-center display-3 fw-bold test-p">
//                                     What they are saying about us
//                                 </p>
//                             </header>
//                             <div
//                                 className="testimonials-slider swiper"
//                                 data-aos="fade-up"
//                                 data-aos-delay={200}
//                             >
//                                 <div className="swiper-wrapper">
//                                     <div className="swiper-slide">
//                                         <div className="testimonial-item">
//                                             <div className="stars">
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                             </div>
//                                             <p>
//                                                 Proin iaculis purus consequat
//                                                 sem cure digni ssim donec
//                                                 porttitora entum suscipit
//                                                 rhoncus. Accusantium quam,
//                                                 ultricies eget id, aliquam eget
//                                                 nibh et. Maecen aliquam, risus
//                                                 at semper.
//                                             </p>
//                                             <div className="profile mt-auto">
//                                                 <img
//                                                     src={testimonials1}
//                                                     className="testimonial-img"
//                                                     alt
//                                                 />
//                                                 <h3>Saul Goodman</h3>
//                                                 <h4>Ceo &amp; Founder</h4>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     {/* End testimonial item */}
//                                     <div className="swiper-slide">
//                                         <div className="testimonial-item">
//                                             <div className="stars">
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                             </div>
//                                             <p>
//                                                 Export tempor illum tamen malis
//                                                 malis eram quae irure esse
//                                                 labore quem cillum quid cillum
//                                                 eram malis quorum velit fore
//                                                 eram velit sunt aliqua noster
//                                                 fugiat irure amet legam anim
//                                                 culpa.
//                                             </p>
//                                             <div className="profile mt-auto">
//                                                 <img
//                                                     src={testimonials2}
//                                                     className="testimonial-img"
//                                                     alt
//                                                 />
//                                                 <h3>Sara Wilsson</h3>
//                                                 <h4>Designer</h4>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     {/* End testimonial item */}
//                                     <div className="swiper-slide">
//                                         <div className="testimonial-item">
//                                             <div className="stars">
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                             </div>
//                                             <p>
//                                                 Enim nisi quem export duis
//                                                 labore cillum quae magna enim
//                                                 sint quorum nulla quem veniam
//                                                 duis minim tempor labore quem
//                                                 eram duis noster aute amet eram
//                                                 fore quis sint minim.
//                                             </p>
//                                             <div className="profile mt-auto">
//                                                 <img
//                                                     src={testimonials3}
//                                                     className="testimonial-img"
//                                                     alt
//                                                 />
//                                                 <h3>Jena Karlis</h3>
//                                                 <h4>Store Owner</h4>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     {/* End testimonial item */}
//                                     <div className="swiper-slide">
//                                         <div className="testimonial-item">
//                                             <div className="stars">
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                             </div>
//                                             <p>
//                                                 Fugiat enim eram quae cillum
//                                                 dolore dolor amet nulla culpa
//                                                 multos export minim fugiat minim
//                                                 velit minim dolor enim duis
//                                                 veniam ipsum anim magna sunt
//                                                 elit fore quem dolore labore
//                                                 illum veniam.
//                                             </p>
//                                             <div className="profile mt-auto">
//                                                 <img
//                                                     src={testimonials4}
//                                                     className="testimonial-img"
//                                                     alt
//                                                 />
//                                                 <h3>Matt Brandon</h3>
//                                                 <h4>Freelancer</h4>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="swiper-slide">
//                                         <div className="testimonial-item">
//                                             <div className="stars">
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                                 <i className="bi bi-star-fill" />
//                                             </div>
//                                             <p>
//                                                 Quis quorum aliqua sint quem
//                                                 legam fore sunt eram irure
//                                                 aliqua veniam tempor noster
//                                                 veniam enim culpa labore duis
//                                                 sunt culpa nulla illum cillum
//                                                 fugiat legam esse veniam culpa
//                                                 fore nisi cillum quid.
//                                             </p>
//                                             <div className="profile mt-auto">
//                                                 <img
//                                                     src={testimonials5}
//                                                     className="testimonial-img"
//                                                     alt
//                                                 />
//                                                 <h3>John Larson</h3>
//                                                 <h4>Entrepreneur</h4>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="swiper-pagination" />
//                             </div>
//                         </div>
//                     </section>
//                 </div>
//             </div>
//         );
//     }
// }

// export default Testinomial;
