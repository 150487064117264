import { Carousel } from "bootstrap";
import React from "react";
import WorkCount from "../../components/workCount/WorkCount";
import Card from "../../components/Cards/Card";
// import Choose from "../../components/Choose/Choose";
import Banner from "../../components/banner/Banner";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Testinomial from "../../components/testimonials/Testinomial";


const Home = () => {
  return (
    <>
    
<div class="elfsight-app-7f39d546-a83e-4ee6-a71e-0fc13ab4f739"></div>
      <Navbar />
      <Banner />
      <Card />
      <WorkCount />
      <Testinomial />
      {/* <Choose /> */}
      <Footer />
      
    </>
  );
};

export default Home;
