import React, { Component } from "react";
import "./style.css";
import portfolio1 from "../../img/portfolio/portfolio-1.jpg";
import portfolio2 from "../../img/portfolio/portfolio-2.jpg";
import portfolio3 from "../../img/portfolio/portfolio-3.jpg";
import portfolio4 from "../../img/portfolio/portfolio-4.jpg";
import portfolio5 from "../../img/portfolio/portfolio-5.jpg";
import portfolio6 from "../../img/portfolio/portfolio-6.jpg";
import portfolio7 from "../../img/portfolio/portfolio-7.jpg";
import portfolio8 from "../../img/portfolio/portfolio-8.jpg";
import portfolio9 from "../../img/portfolio/portfolio-9.jpg";

export default class PortoNew extends Component {
  render() {
    return (
      <div>
        <section id="portfolio" className="portfolio">
          <div className="container">
            <div className="row" data-aos="fade-up">
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="portfolio-flters">
                  <li data-filter=".filter-polad" >Polad 500</li>
                  <li data-filter=".filter-app">Water Proffing</li>
                  <li data-filter=".filter-card">Steel Railings</li>
                  <li data-filter=".filter-web">jk Cement</li>
                  <li data-filter=".filter-rolling">Rolling Shutters</li>
                </ul>
              </div>
            </div>
            <div className="row portfolio-container" data-aos="fade-up">
            <div className="col-lg-12 col-md-6 portfolio-item filter-polad">
                <div class="container porto mx-auto">
                  <div class="row">
                    <div className="waterproof-1 col-lg-6 col-sm-12">
                      <h1 className="">
                        lorem ipsum dolor sit amet, consectetur adipiscing
                        element
                      </h1>
                      <p className="w-70">
                        Lorem ipsum dolor sit amet, consectetur adip elit, sed
                        do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat
                      </p>
                      <p className="mt-5">
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="col-lg-12 col-md-6 portfolio-item filter-app">
                <div class="container porto mx-auto">
                  <div class="row">
                    <div className="waterproof-2 col-lg-6 col-sm-12">
                      <h1 className="">
                        lorem ipsum dolor sit amet, consectetur adipiscing
                        element
                      </h1>
                      <p className="w-70">
                        Lorem ipsum dolor sit amet, consectetur adip elit, sed
                        do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat
                      </p>
                      <p className="mt-5">
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-6 portfolio-item filter-card">
              <div class="container porto mx-auto">
                  <div class="row">
                    <div className="waterproof-1 col-lg-6 col-sm-12">
                      <h1 className="">
                        lorem ipsum dolor sit amet, consectetur adipiscing
                        element
                      </h1>
                      <p className="w-70">
                        Lorem ipsum dolor sit amet, consectetur adip elit, sed
                        do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat
                      </p>
                      <p className="mt-5">
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-md-6 portfolio-item filter-web">
              <div class="container porto mx-auto">
                  <div class="row">
                    <div className="waterproof-2 col-lg-6 col-sm-12">
                      <h1 className="">
                        lorem ipsum dolor sit amet, consectetur adipiscing
                        element
                      </h1>
                      <p className="w-70">
                        Lorem ipsum dolor sit amet, consectetur adip elit, sed
                        do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat
                      </p>
                      <p className="mt-5">
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-6 portfolio-item filter-rolling">
              <div class="container porto mx-auto">
                  <div class="row">
                    <div className="waterproof-1 col-lg-6 col-sm-12">
                      <h1 className="">
                        lorem ipsum dolor sit amet, consectetur adipiscing
                        element
                      </h1>
                      <p className="w-70">
                        Lorem ipsum dolor sit amet, consectetur adip elit, sed
                        do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat
                      </p>
                      <p className="mt-5">
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                        1.lore ......................... fghdctc gcdgfdc hgfhc
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
