import React from 'react'

import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

import rolling from "../../img/rolling.jpeg";
import kanagi from "../../img/kanagi.jpg";
import rollingShutters from "../../img/product/rolling_shutters.jpeg";
import ssrailing from "../../img/product/ss_railing.jpeg";
import kichentrolly from "../../img/product/kichen_trolly.jpeg";
import ssrailing1 from "../../img/product/ss_railing1.jpeg";
import stainless from "../../img/product/stainless.jpeg";
import steelrailing from "../../img/product/steelRailing.jpeg";
import Portfolio from "../Portfolio/Portfolio";

import Grid from '../Grid/Grid';


const SsRailing = () => {
    return (
        <>
        <Navbar />
            <div>
                <div classname="banner">
                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            

                            <div className="carousel-item active">
                                <img src={ssrailing} className="d-block w-100 img-fluid" alt="..." />
                            </div>

                            <div className="carousel-item">
                                <img src={kichentrolly} className="d-block w-100 img-fluid" alt="..." />
                            </div>

                            <div className="carousel-item">
                                <img src={ssrailing1} className="d-block w-100 img-fluid" alt="..." />
                            </div>

                            <div className="carousel-item">
                                <img src={stainless} className="d-block w-100 img-fluid" alt="..." />
                            </div>

                            <div className="carousel-item">
                                <img src={steelrailing} className="d-block w-100 img-fluid" alt="..." />
                            </div>


                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true" />
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true" />
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>



                <section id="services" class="services section-bg bg-light">
                    <div className="d-flex justify-content-center align-items-center ">
                        <div className="count-title pt-5">
                            <h1 className="mx-auto text-primary pb-5">
                                Features
                            </h1>
                        </div>
                    </div>
                    <div class="container" data-aos="fade-up">

                        <div class="row">
                            <div
                                class="col-lg-3 col-md-6 d-flex align-items-stretch"
                                data-aos="zoom-in"
                                data-aos-delay="50"
                            >
                               <div class="icon-box iconbox-pink">
                                    <div class="icon">
                                    
                                    </div>
                                    <h4>
                                        <a href="">Enhances Concrete Durability.</a>
                                    </h4>
                                    <p>
                                    Due to environmental (air, water & soil) pollution, durability of any structure is always a vital concern; JK Super Strong is an answer for all such concerns.
                                    </p>
                                </div>
                            </div>

                            <div
                                class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                                data-aos="zoom-in"
                                data-aos-delay="200"
                            >
                               <div class="icon-box iconbox-pink">
                                    <div class="icon">
                                    
                                    </div>
                                    <h4>
                                        <a href="">Corrosion Resistant.</a>
                                    </h4>
                                    <p>
                                    This product is specially designed for concrete but can be used safely for any cement application like masonry, plastering, flooring etc. The concrete produced with this cement is superior in quality and therefore is highly recommended for:
                                    </p>
                                </div>
                            </div>

                            <div
                                class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
                                data-aos="zoom-in"
                                data-aos-delay="300"
                            >
                               <div class="icon-box iconbox-pink">
                                    <div class="icon">
                                    
                                    </div>
                                    <h4>
                                        <a href="">Anti Efflorescence</a>
                                    </h4>
                                    <p>
                                    Due to enhanced particle size distribution and micro air inclusion, it always gives better workability in comparison to normal cement.
                                    </p>
                                </div>
                            </div>

                            <div
                                class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
                                data-aos="zoom-in"
                                data-aos-delay="300"
                            >
                               <div class="icon-box iconbox-pink">
                                    <div class="icon">
                                    
                                    </div>
                                    <h4>
                                        <a href="">Reduced Salt Efflorescence</a>
                                    </h4>
                                    <p>
                                    Excellent water penetration resistance of JK Super Strong Weather Shield Cement helps in prevention of salt efflorescence on the surface of mortar and concrete.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    


                </section>
                <Grid />
            </div>
            <Footer />
        </>
    )
}

export default SsRailing
