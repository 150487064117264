import React from "react";
import "./about.css";
import { GrCertificate } from "react-icons/gr";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import roadmap from "../../img/roadmap.png";
import Team from "../../pages/Aboutus/Team";
import dot from "../../img/dot.png";
const AboutUs = () => {
  return (
    <>
      <Navbar />
{/* 
        <div className="biography">
            <div className="about-main">
              <h1 className="bio-heading">Biography</h1>
              <p className="">
                A life's work of embracing both the creative and the quantitative,
                developing thriving online businesses and enviable brands.
              </p>
            </div>

            <div className="bio-para">
              <div className="">
                <p className="about-para">
                  Sit amet massa vitae tortor condimentum lacinia quis. Ornare
                  arcu dui vivamus arcu felis bibendum ut. Consectetur adipiscing
                  elit duis tristique sollicitudin. Volutpat lacus laoreet non
                  curabitur. Magna fringilla urna porttitor rhoncus. Ultricies leo
                  integer malesuada nunc vel risus commodo viverra. Ipsum a arcu
                  cursus vitae congue. Imperdiet dui accumsan sit amet nulla
                  facilisi. Tincidunt dui ut ornare lectus sit. Phasellus faucibus
                  scelerisque eleifend donec pretium vulputate sapien nec. Eget
                  gravida cum sociis natoque.
                </p>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm col-md-3 mt-5 contact-btn pt-3"
                >
                  <p className="text-center me-6">Contact</p>
                </button>
              </div>
            </div>
        </div> */}

        <section className="roadmap">
          <div>
            <h1 className="text-center pb-4">Journey Of Tultech</h1>
            {/* <img src={dot} className="dot d-block img-fluid"  alt="..." /> */}
            <img src={roadmap} className="d-block w-100 img-fluid" alt="..." />
          </div>
        </section>
        
        
        
        <Team />
      <Footer />
    </>
  );
};

export default AboutUs;
