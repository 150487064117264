import React from "react";
import "./work.css";
import blog1 from "../../img/blog/blog-1.jpg";
import blog2 from "../../img/blog/blog-2.jpg";
import blog3 from "../../img/blog/blog-3.jpg";
import { AiOutlinePlus } from "react-icons/ai";
import { BiMap } from "react-icons/bi";
import work4 from "../../img/work4.jpg";
import work2 from "../../img/work2.jpeg";
import kitchenTrolly from "../../img/kitchenTrolly.jpg";



const WorkCount = () => {
  return (
    <>
      <section className="count bg-light">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center ">
            <div className="section-title">
              <h2 className="mx-auto pb-5 countTitle">
                Count Speaking Our Growth
              </h2>
            </div>
          </div>
          <div className="count-boxes d-flex">
            <div className="count-box">
              <div>
                <div className="count-str">
                  <strong>1000+</strong>
                </div>

                <h4>
                  {/* <hr color="black" width="120" className="mb-2" /> */}
                  Grain Sturage
                </h4>
              </div>
            </div>
            <div className="count-box">
              <div>
                <div className="count-str">
                  <strong>100+</strong>
                </div>

                <h4>
                  {/* <hr color="black" width="120" className="mb-2" /> */}
                  Shutter
                </h4>
              </div>
            </div>
            <div className="count-box">
              <div>
                <div className="count-str">
                  <strong>180+</strong>
                </div>

                <h4>
                  {/* <hr color="black" width="120" className="mb-2" /> */}
                  SS railing
                </h4>
              </div>
            </div>
            <div className="count-box">
              <div>
                <div className="count-str">
                  <strong>78+</strong>
                </div>

                <h4>
                  {/* <hr color="black" width="120" className="mb-2" /> */}
                  Kitchen Trolley
                </h4>
              </div>
            </div>
            <div className="count-box">
              <div>
                <div className="count-str">
                  <strong>201+</strong>
                </div>

                <h4>
                  {/* <hr color="black" width="120" className="mb-2" /> */}
                  Waterproofing
                </h4>
              </div>
            </div>
          </div>
        </div>

      </section>

      <section id="recent-blog-posts" className="recent-blog-posts">
        <div className="container" data-aos="fade-up">
          <header className="section-title">
            <h2 className="text-center">Our Work<p className="text-center">
              {" "}
              Recent posts form our recent work are shown below :
            </p></h2>
            
          </header>
          <div className="row">
            <div className="col-lg-4">
              <div className="post-box">
                <div className="post-img">
                  <img
                    src={work2}
                    className="img-fluid"
                    alt
                  />
                </div>
                <div className="small-circle"><img src={blog1} className="img-fluid portfolio-img" /></div>
                <span className="post-date d-flex">
                  <BiMap
                    className=""
                    color="red"
                    size="3rem"
                  />
                  <p className="pt-2">Mon, July 11</p>
                </span>
                <h3 className="post-title">
                  Warnali Apartment
                </h3>
                <p>
                  Beautiful Huge 1 family House in
                  heart of Westbury Newly Renovated
                  new Furniture
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="post-box">
                <div className="post-img">
                  <img
                    src={work2}
                    className="img-fluid"
                    alt
                  />
                </div>
                <div className="small-circle"><img src={blog3} className="img-fluid portfolio-img" /></div>
                <span className="post-date d-flex">
                  <BiMap
                    className=""
                    color="red"
                    size="3rem"
                  />
                  <p className="pt-2">Mon, July 11</p>
                </span>
                <h3 className="post-title">
                  Warnali Apartment
                </h3>
                <p>
                  Beautiful Huge 1 family House in
                  heart of Westbury Newly Renovated
                  new Furniture
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="post-box">
                <div className="post-img">
                  <img
                    src={work2}
                    className="img-fluid "
                    alt
                  />
                </div>
                <div className="small-circle"><img src={blog3} className="img-fluid portfolio-img" /></div>
                <span className="post-date d-flex">
                  <BiMap
                    className=""
                    color="red"
                    size="3rem"
                  />
                  <p className="pt-2">Mon, July 11</p>
                </span>
                <h3 className="post-title">
                  Warnali Apartment
                </h3>
                <p>
                  Beautiful Huge 1 family House in
                  heart of Westbury Newly Renovated
                  new Furniture
                </p>
              </div>
            </div>
          </div>

          <div className="row pt-4">
            <div className="col-lg-4">
              <div className="post-box">
                <div className="post-img">
                  <img
                    src={work2}
                    className="img-fluid"
                    alt
                  />
                </div>
                <div className="small-circle"><img src={blog3} className="img-fluid portfolio-img" /></div>
                <span className="post-date d-flex">
                  <BiMap
                    className=""
                    color="red"
                    size="3rem"
                  />
                  <p className="pt-2">Mon, July 11</p>
                </span>
                <h3 className="post-title">
                  Warnali Apartment
                </h3>
                <p>
                  Beautiful Huge 1 family House in
                  heart of Westbury Newly Renovated
                  new Furniture
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="post-box">
                <div className="post-img">
                  <img
                    src={work2}
                    className="img-fluid"
                    alt
                  />
                </div>
                <div className="small-circle"><img src={blog3} className="img-fluid portfolio-img" /></div>
                <span className="post-date d-flex">
                  <BiMap
                    className=""
                    color="red"
                    size="3rem"
                  />
                  <p className="pt-2">Mon, July 11</p>
                </span>
                <h3 className="post-title">
                  Warnali Apartment
                </h3>
                <p>
                  Beautiful Huge 1 family House in
                  heart of Westbury Newly Renovated
                  new Furniture
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="post-box">
                <div className="post-img">
                  <img
                    src={work2}
                    className="img-fluid "
                    alt
                  />
                </div>
                <div className="small-circle"><img src={blog3} className="img-fluid portfolio-img" /></div>
                <span className="post-date d-flex">
                  <BiMap
                    className=""
                    color="red"
                    size="3rem"
                  />
                  <p className="pt-2">Mon, July 11</p>
                </span>
                <h3 className="post-title">
                  Warnali Apartment
                </h3>
                <p>
                  Beautiful Huge 1 family House in
                  heart of Westbury Newly Renovated
                  new Furniture
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 
<section id="services" class="services section-bg my-5">
<div className="d-flex justify-content-center align-items-center ">
    <div className="count-title pt-5">
      <h1 className="mx-auto text-primary pb-5">
        Features
      </h1>
    </div>
  </div>
                  <div class="container" data-aos="fade-up">
                      <div class="row">
                          <div
                              class="col-lg-3 col-md-6 d-flex align-items-stretch"
                              data-aos="zoom-in"
                              data-aos-delay="50"
                          >
                              <div class="icon-box iconbox-blue">
                                  <div class="icon">
                                      <i class="bx bxl-dribbble"></i>
                                  </div>
                                  <h4>
                                      <a href="">Lorem Ipsum</a>
                                  </h4>
                                  <p>
                                      Voluptatum deleniti atque corrupti
                                      quos dolores et quas molestias
                                      excepturi
                                  </p>
                              </div>
                          </div>

                          <div
                              class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                              data-aos="zoom-in"
                              data-aos-delay="200"
                          >
                              <div class="icon-box iconbox-orange ">
                                  <div class="icon">
                                      <i class="bx bx-file"></i>
                                  </div>
                                  <h4>
                                      <a href="">Sed Perspiciatis</a>
                                  </h4>
                                  <p>
                                      Duis aute irure dolor in
                                      reprehenderit in voluptate velit
                                      esse cillum dolore
                                  </p>
                              </div>
                          </div>

                          <div
                              class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
                              data-aos="zoom-in"
                              data-aos-delay="300"
                          >
                              <div class="icon-box iconbox-pink">
                                  <div class="icon">
                                      <i class="bx bx-tachometer"></i>
                                  </div>
                                  <h4>
                                      <a href="">Magni Dolores</a>
                                  </h4>
                                  <p>
                                      Excepteur sint occaecat cupidatat
                                      non proident, sunt in culpa qui
                                      officia
                                  </p>
                              </div>
                          </div>

                          <div
                              class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
                              data-aos="zoom-in"
                              data-aos-delay="300"
                          >
                              <div class="icon-box iconbox-pink">
                                  <div class="icon">
                                      <i class="bx bx-tachometer"></i>
                                  </div>
                                  <h4>
                                      <a href="">Magni Dolores</a>
                                  </h4>
                                  <p>
                                      Excepteur sint occaecat cupidatat
                                      non proident, sunt in culpa qui
                                      officia
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>

                 <div class="container block">
                      <div class="row">
                          <div className="block-1 col-lg-6 col-sm-12 justify-content-between">
                              
                              <strong className="mt-5 display-2">
                                  New Site with polad 500 and
                                  <br />
                                  jk Cement In Atpadi
                                  <br />
                              </strong>
                              <button className="btn-primary btn-lg mt-5 px-5">
                                  See all sites
                              </button>
                          </div>

                          <div className="block-2 col-lg-6 col-sm-12">
                              <div className="blockMap">
                                  <img
                                      src="https://images.pexels.com/photos/4452373/pexels-photo-4452373.jpeg?cs=srgb&dl=pexels-vlada-karpovich-4452373.jpg&fm=jpg"
                                      className="img-fluid"
                                  />
                                  
                              <div className="blockVid ">
                                  <video className="img-fluid" controls>
                                      <source src={cement} />
                                  </video>
                              </div>
                              </div>
                              
                          </div> 
                      </div>
                  </div>
              </section> */}
    </>



  );
};

export default WorkCount;
