import React from 'react'
import './style.css';
const Grid = () => {
    return (
        <div>
             <div className="workGallery " data-aos="fade-up">
      <div className="text-center"><h3 id="workh1">Discover the power of unity</h3></div>
      <div className="workHeaderPara border-1"><p className="text-center">As noted above, each of these breakpoints have their own container, unique class prefix, and modifiers. Here’s how the grid changes across these breakpoints:<br />
      As noted above, each of these breakpoints have their own container, unique class prefix,<br />
       and modifiers. Here’s how the grid changes across these breakpoints:</p></div>
        <div class="container ">
          <div class="row mb-5">
            <div class="col-lg-3 thumbnail">
              <img
                src="https://scontent.fbom12-1.fna.fbcdn.net/v/t1.6435-9/64781648_1277496965764785_2413814015974178816_n.jpg?_nc_cat=104&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=fKNYziatiuAAX8A_Iuz&_nc_ht=scontent.fbom12-1.fna&oh=00_AT_EkTImvYQG7l7wyKETZDM1cxTXnBIgLm7dqyUZnG5bkA&oe=6209B230"
                height="200"
              />
            </div>
            <div class="col-lg-2 thumbnail">
              <img
                src="https://scontent.fbom12-1.fna.fbcdn.net/v/t1.6435-9/51951455_1183263205188162_4368172526774255616_n.jpg?_nc_cat=101&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=ANtaFNGquywAX-iLnZe&_nc_ht=scontent.fbom12-1.fna&oh=00_AT8ZS-keX3ifvE1Juqxh06_tRsdncyLNZQEiiW0TdGb3bQ&oe=62088703"
                height="200"
              />
            </div>
            <div class="col-lg-3 thumbnail">
              <img
                src="https://scontent.fbom12-1.fna.fbcdn.net/v/t1.18169-9/31437418_952496484931503_2352774867761170150_n.jpg?_nc_cat=103&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=8wf8bl6FSDsAX_nwr3d&tn=_lOt__AvwFacUd-5&_nc_ht=scontent.fbom12-1.fna&oh=00_AT8pX3AbRDN59z0Tofqx52XrPMSH2ZyxQfEiaJF2-6du5Q&oe=6208C9FC"
                height="200"
              />
            </div>
            <div class="col-lg-4 thumbnail">
              <img
                src="https://scontent.fbom12-1.fna.fbcdn.net/v/t1.6435-9/36121223_993882697459548_8223354550556295168_n.jpg?_nc_cat=109&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=VsrFCoTiJUIAX9cH_nH&tn=_lOt__AvwFacUd-5&_nc_ht=scontent.fbom12-1.fna&oh=00_AT_plGY9INf-6hUjD16fITWH9-X0Xu97z6IDdnXUF4BhHA&oe=620996BA"
                height="200"
              />
            </div>
          </div>

          <div class="row mb-5">
            <div className="col-lg-3">
              <img
                src="https://scontent.fbom12-1.fna.fbcdn.net/v/t1.6435-9/36114181_993882814126203_2646581027006316544_n.jpg?_nc_cat=103&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=VgK3Dj-KmL0AX92tjoj&tn=_lOt__AvwFacUd-5&_nc_ht=scontent.fbom12-1.fna&oh=00_AT9BwAAen2Gn_jCWAULlh_uuzE9PV1xUYUktUX9oVpLsQA&oe=6207CA30"
                height="405"
              />
            </div>
            <div className="col-lg-9">
              <div className=" row col-12 mb-2">
              <div className=" col-3"><img
                src="https://scontent.fbom12-1.fna.fbcdn.net/v/t1.6435-9/52292248_1183263171854832_397515934962548736_n.jpg?_nc_cat=110&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=oNIJTmh2fd0AX-Wqapn&_nc_ht=scontent.fbom12-1.fna&oh=00_AT_SYVEtB8iG5AuHWYOh4PvSHOwQEcjw-LjH7SmhikPcDg&oe=62093399"
                height="200"
              /></div>
              <div className=" col-5"><img
                src="https://scontent.fbom12-1.fna.fbcdn.net/v/t39.30808-6/269782804_2089581107889696_8190126346183950354_n.jpg?_nc_cat=111&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=WKnyGhamQfcAX8nZ1wH&_nc_ht=scontent.fbom12-1.fna&oh=00_AT9u3FHtrUVUU5x7iQ03-Bq0EHrNmeqN7dzbq7eoafrqpA&oe=61E7F09B"
                height="200"
              /></div>
              <div className=" col-4"><img
                src="https://scontent.fbom12-1.fna.fbcdn.net/v/t1.6435-9/48367946_1142597422588074_6651120096125124608_n.jpg?_nc_cat=107&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=hNbGofh7Oo8AX_xdA_b&tn=_lOt__AvwFacUd-5&_nc_ht=scontent.fbom12-1.fna&oh=00_AT8NAZ7v-bH3R0FGm7XC0ldd9296eHxlas6qVP-abIccgA&oe=620A72DD"
                height="200"
              /></div>
              
              </div>

              <div className=" row col-12">
              <div className=" col-4"><img
                src="https://scontent.fbom12-1.fna.fbcdn.net/v/t1.6435-9/45182265_1108699999311150_6394033588693630976_n.jpg?_nc_cat=100&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=_g9xq7WxqMAAX9_AQ0-&_nc_ht=scontent.fbom12-1.fna&oh=00_AT-jYHhkjbR1yb45c5P1SDJXszR5oekaowBsgA7nEBG5jg&oe=6208F0BD"
                height="200"
              /></div>
              <div className=" col-3"><img
                src="https://scontent.fbom12-1.fna.fbcdn.net/v/t1.6435-9/125365701_1755794711268339_5824448041804212709_n.jpg?_nc_cat=107&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=RdS647s0XHIAX8ijMEM&_nc_ht=scontent.fbom12-1.fna&oh=00_AT9qhKU8od5dhVTdzRkkCrzJNQ17y2dQ1Ta-d7buSd-YKg&oe=620A449D"
                height="200"
              /></div>
              <div className=" col-5"><img
                src="https://scontent.fbom12-1.fna.fbcdn.net/v/t1.6435-9/46893548_1124350957746054_1744104794915602432_n.jpg?_nc_cat=108&ccb=1-5&_nc_sid=8bfeb9&_nc_ohc=2pttwOwYf20AX9zS-hV&tn=_lOt__AvwFacUd-5&_nc_ht=scontent.fbom12-1.fna&oh=00_AT-Sdul5xFr6K_36yQI39ukfd7M3eOTzMAcOH7GISi_m3w&oe=6209A5BC"
                height="200"
              /></div>
              
              </div>



            </div>
          </div>

          <div class="row mb-5"></div>
        </div>
      </div>
        </div>
    )
}

export default Grid
