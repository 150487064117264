import React from "react";

import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

import rolling from "../../img/rolling.jpeg";
import kanagi from "../../img/kanagi.jpg";
import rollingShutters from "../../img/product/rolling_shutters.jpeg";
import ssrailing from "../../img/product/ss_railing.jpeg";
import kichentrolly from "../../img/product/kichen_trolly.jpeg";
import ssrailing1 from "../../img/product/ss_railing1.jpeg";
import stainless from "../../img/product/stainless.jpeg";
import steelrailing from "../../img/product/steelRailing.jpeg";
import Portfolio from "../Portfolio/Portfolio";

import Grid from "../Grid/Grid";

const Steel = () => {
    return (
        <>
            <Navbar />
            <div>
                <div classname="banner">
                    <div
                        id="carouselExampleControls"
                        className="carousel slide"
                        data-bs-ride="carousel"
                    >
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img
                                    src={rolling}
                                    className="d-block w-100 img-fluid"
                                    alt="..."
                                />
                            </div>

                            <div className="carousel-item">
                                <img
                                    src={rollingShutters}
                                    className="d-block w-100 img-fluid"
                                    alt="..."
                                />
                            </div>

                            <div className="carousel-item">
                                <img
                                    src={ssrailing}
                                    className="d-block w-100 img-fluid"
                                    alt="..."
                                />
                            </div>

                            <div className="carousel-item">
                                <img
                                    src={kichentrolly}
                                    className="d-block w-100 img-fluid"
                                    alt="..."
                                />
                            </div>

                            <div className="carousel-item">
                                <img
                                    src={ssrailing1}
                                    className="d-block w-100 img-fluid"
                                    alt="..."
                                />
                            </div>

                            <div className="carousel-item">
                                <img
                                    src={stainless}
                                    className="d-block w-100 img-fluid"
                                    alt="..."
                                />
                            </div>

                            <div className="carousel-item">
                                <img
                                    src={steelrailing}
                                    className="d-block w-100 img-fluid"
                                    alt="..."
                                />
                            </div>
                        </div>
                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselExampleControls"
                            data-bs-slide="prev"
                        >
                            <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                            />
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselExampleControls"
                            data-bs-slide="next"
                        >
                            <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                            />
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>

                <section id="services" class="services section-bg bg-light">
                    <div className="d-flex justify-content-center align-items-center ">
                        <div className="count-title pt-5">
                            <h1 className="mx-auto text-primary pb-5">
                                Features
                            </h1>
                        </div>
                    </div>
                    <div class="container" data-aos="fade-up">
                        <div class="row">
                            <div
                                class="col-lg-3 col-md-6 d-flex align-items-stretch"
                                data-aos="zoom-in"
                                data-aos-delay="50"
                            >
                                <div class="icon-box iconbox-pink">
                                    <div class="icon">
                                    
                                    </div>
                                    <h4>
                                        <a href="">Stronger that iron</a>
                                    </h4>
                                    <p>
                                        roughly 1,000 times stronger than iron
                                        in its purest form
                                    </p>
                                </div>
                            </div>

                            <div
                                class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                                data-aos="zoom-in"
                                data-aos-delay="200"
                            >
                                <div class="icon-box iconbox-pink ">
                                    <div class="icon">
                                    
                                    </div>
                                    <h4>
                                        <a href="">Totally recyclable</a>
                                    </h4>
                                    <p>
                                        Steel is one of the most recycled
                                        materials on the planet without losing
                                        any of its strength
                                    </p>
                                </div>
                            </div>

                            <div
                                class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
                                data-aos="zoom-in"
                                data-aos-delay="300"
                            >
                                <div class="icon-box iconbox-pink">
                                    <div class="icon">
                                        
                                    </div>
                                    <h4>
                                        <a href="">Unimaginable strength</a>
                                    </h4>
                                    <p>
                                        Skyscrapers first were built with steel,
                                        even though it is light in weight.
                                    </p>
                                </div>
                            </div>

                            <div
                                class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
                                data-aos="zoom-in"
                                data-aos-delay="300"
                            >
                                <div class="icon-box iconbox-pink">
                                    <div class="icon">
                                        
                                    </div>
                                    <h4>
                                        <a href="">corrosive resistance</a>
                                    </h4>
                                    <p>
                                        high strength, low weight, durability,
                                        ductility and corrosive resistance.
                                        Steel offers great strength,
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </section>
                <Grid />
            </div>
            <Footer />
        </>
    );
};

export default Steel;
