import React from "react";
import logo from "../../img/logo.jpg";
import "./style.css";
import { Divider } from "@material-ui/core";
import { Image } from "react-bootstrap";
const Footer = () => {
    return (
        <div>
            <footer
                className=""
                style={{
                    backgroundColor: "#ffffff",
                    marginTop: "4em",
                }}
            >
                <Divider />
                <div className="container mt-4 pt-5 border-bottom">
                    <div className="row m-auto">
                        <div className="col-md-3 col-sm-12 mb-3">
                            <img src={logo} width="100" height="150" />
                        </div>
                        <div className="col-md-9 col-sm-12">
                            <div className="row mx-1 ">
                                <div className="col-md-6 col-sm-2 col-6 p-0 mb-3">
                                    {/* <h6 className="mb-4 font-weight-bold text-uppercase">Contact us</h6>
                                    <ul className="list-group">
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            Nitin Nimagre +91 9764575751
                                        </li><li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            Dinesh Nimagre +91 9096313226
                                            </li><li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            Dattaray Shejal +91 7218569900
                                        </li>
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            Email ID: shreeram.ent1810@rediffmail.com
                                        </li>
                                    </ul> */}
                                </div>

                                <div className="col-md-2 col-sm-6 col-6 p-0 mb-3">
                                    <h6 className="mb-4 font-weight-bold text-uppercase">
                                        Services
                                    </h6>
                                    <ul className="list-group">
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            <a
                                                href="https://www.5techg.com"
                                                className="text-dark"
                                            >
                                                Rolling shutters
                                            </a>
                                        </li>
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            <a
                                                href="https://www.5techg.com"
                                                className="text-dark"
                                            >
                                                Cement 
                                            </a>
                                        </li>
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            <a
                                                href="https://www.5techg.com"
                                                className="text-dark"
                                            >
                                                SS Roiling 
                                            </a>
                                        </li>
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            <a
                                                href="https://www.5techg.com"
                                                className="text-dark"
                                            >
                                                Kitchentrolley
                                            </a>
                                        </li>
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            <a
                                                href="https://www.5techg.com"
                                                className="text-dark"
                                            >
                                                steel
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-md-2 col-sm-12 col-6 mb-3 p-0">
                                    <h6 className="mb-4 font-weight-bold text-uppercase">
                                        Company
                                    </h6>
                                    <ul className="list-group">
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            <a href="#" className="text-dark">
                                                About
                                            </a>
                                        </li>
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            <a href="#" className="text-dark">
                                                Privacy
                                            </a>
                                        </li>
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            <a href="#" className="text-dark">
                                                Terms
                                            </a>
                                        </li>
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            <a href="#" className="text-dark">
                                                History
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-md-2 col-sm-12 col-6 mb-3 p-0">
                                    <h6 className="mb-4 font-weight-bold text-uppercase">
                                        Connect
                                    </h6>
                                    <ul className="list-group">
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            <a
                                                href="https://www.linkedin.com/company/"
                                                className="text-dark"
                                            >
                                                <i className="fa fa-linkedin mr-1"></i>{" "}
                                                LinkedIn
                                            </a>
                                        </li>
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            <a
                                                href="https://twitter.com/"
                                                className="text-dark"
                                            >
                                                <i className="fa fa-instagram mr-1"></i>{" "}
                                                Instagram
                                            </a>
                                        </li>

                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            <a
                                                href="https://www.facebook.com/tultechrollingshutters/?ref=page_internal"
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                className="text-dark"
                                            >
                                                <i className="fa fa-facebook mr-1"></i>{" "}
                                                Facebook
                                            </a>
                                        </li>
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            <a
                                                href="https://www.youtube.com"
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                className="text-dark"
                                            >
                                                <i className="fa fa-youtube mr-1"></i>{" "}
                                                YouTube
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="py-4 d-flex justify-content-center align-items-center">
                                designed and developed by{" "}
                                <a
                                    href="https://www.5techg.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    &nbsp;5TechG Lab
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* whatsapp floating button */}
            </footer>
        </div>
    );
};

export default Footer;
