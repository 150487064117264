import React, { Component } from "react";
import "./style.css";
import blog1 from "../../img/blog/blog-1.jpg";
import blog2 from "../../img/blog/blog-2.jpg";
import blog3 from "../../img/blog/blog-3.jpg";
import { BiMap } from "react-icons/bi";
import cement from "../../img/cement1.mp4";
import PortoNew from "./PortoNew";

export default class Portfolio extends Component {
    render() {
        return (
            <>
                    <section
                        id="recent-blog-posts"
                        className="recent-blog-posts"
                    >
                        <div className="container" data-aos="fade-up">
                            <header className="section-header">
                                <h1 className="display-3 text-center">Our recent Work</h1>
                                <p className="text-center">
                                    {" "}
                                    Recent posts form our recent work are shown below :
                                </p>
                            </header>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="post-box">
                                        <div className="post-img">
                                            <img
                                                src={blog1}
                                                className="img-fluid"
                                                alt
                                            />
                                        </div>
                                        <div className="small-circle"><img src={blog3} className="img-fluid portfolio-img" /></div>
                                        <span className="post-date d-flex">
                                            <BiMap
                                                className=""
                                                color="red"
                                                size="3rem"
                                            />
                                            <p className="pt-2">Mon, July 11</p>
                                        </span>
                                        <h3 className="post-title">
                                            Warnali Apartment
                                        </h3>
                                        <p>
                                            Beautiful Huge 1 family House in
                                            heart of Westbury Newly Renovated
                                            new Furniture
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="post-box">
                                        <div className="post-img">
                                            <img
                                                src={blog2}
                                                className="img-fluid"
                                                alt
                                            />
                                        </div>
                                        <div className="small-circle"><img src={blog3} className="img-fluid portfolio-img" /></div>
                                        <span className="post-date d-flex">
                                            <BiMap
                                                className=""
                                                color="red"
                                                size="3rem"
                                            />
                                            <p className="pt-2">Mon, July 11</p>
                                        </span>
                                        <h3 className="post-title">
                                            Warnali Apartment
                                        </h3>
                                        <p>
                                            Beautiful Huge 1 family House in
                                            heart of Westbury Newly Renovated
                                            new Furniture
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="post-box">
                                        <div className="post-img">
                                            <img
                                                src={blog3}
                                                className="img-fluid "
                                                alt
                                            />
                                        </div>
                                        <div className="small-circle"><img src={blog3} className="img-fluid portfolio-img" /></div>
                                        <span className="post-date d-flex">
                                            <BiMap
                                                className=""
                                                color="red"
                                                size="3rem"
                                            />
                                            <p className="pt-2">Mon, July 11</p>
                                        </span>
                                        <h3 className="post-title">
                                            Warnali Apartment
                                        </h3>
                                        <p>
                                            Beautiful Huge 1 family House in
                                            heart of Westbury Newly Renovated
                                            new Furniture
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

            </>
        );
    }
}
