import React from 'react'

import team from "../../img/team.jpg";
import "./team.css";

const Team = () => {
    return (

        <section class="bg-white">
            <div className="px-5">
                <img src={team} className="d-block d-flex w-100 img-fluid rounded" alt="..." />
            </div>

        </section>
    )
}

export default Team
