import React from "react";
import "./style.css";
import steel from '../../img/polad.jpg'
import ssRailing from '../../img/ssrailing.jpg'
import cement from '../../img/jkCement.jpg'

import shutter from "../../img/banner2.jpg";
import { NavLink } from "react-router-dom";
const Card = () => {
  return (
    <div>
      
      <section id="team" className="team bg-light">
        <div className="container">
          <div className="section-title" >
            <h2 className="team-head">Our Key Product</h2>
          </div>
          <div className="row mt-5">
            <div className="col-lg-3 col-md-6 d-flex align-items-stretch cards">
              <div className="member" data-aos="fade-up" data-aos-delay={100}>
                <div className="member-img p-4">
                <NavLink to="/productDetails">
                  <img
                    src={steel}
                    className="img-fluid"
                    alt=""
                  /></NavLink>
                </div>
                <div className="member-info">
                <NavLink to="/productDetails"><h4 className="text-center">Steel </h4></NavLink>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 d-flex align-items-stretch cards">
              <div className="member" data-aos="fade-up" data-aos-delay={100}>
                <div className="member-img p-4">
                  <img
                    src={ssRailing}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="member-info">
                <NavLink to="/steelRailing"><h4 className="text-center">Steel Railing</h4></NavLink>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 d-flex align-items-stretch cards">
              <div className="member" data-aos="fade-up" data-aos-delay={100}>
                <div className="member-img p-4">
                  <img
                    src={shutter}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="member-info">
                  <NavLink to="/rollingShutters"><h4 className="text-center">Rolling Shutters</h4></NavLink>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 d-flex align-items-stretch cards">
              <div className="member" data-aos="fade-up" data-aos-delay={100}>
                <div className="member-img p-4">
                  <img
                    src={cement}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="member-info">
                <NavLink to="/ssRailing"><h4 className="text-center">Jk Cement</h4></NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="my-5 container">
      <div className="button-grp d-flex flex-direction-row mt-2">
        
        <button type="button" class="btn buttn btn-lg me-1 col-2">Rolling shutters</button>
        <button type="button" class="btn buttn btn-lg me-1 col-2"> Grain storage </button>
        <button type="button" class="btn buttn btn-lg me-1 col-2">Kitchentrolley</button>
        <button type="button" class="btn buttn btn-lg me-1 col-2">Waterproofing  </button>
       
        
        </div>
        <div className="button-grp d-flex flex-direction-row mt-4">
        
        <button type="button" class="btn buttn btn-lg me-1 col-2">Cement</button>
        <button type="button" class="btn buttn btn-lg me-1 col-2">Steel</button>
        <button type="button" class="btn buttn btn-lg me-1 col-2">SS railing</button>
       
        
        </div>
      </div>
    </div>
  );
};

export default Card;
