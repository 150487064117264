import React from 'react'
import './style.css';

import Navbar from '../../../components/navbar/Navbar';
import Footer from '../../../components/footer/Footer';
import mykCement from "../../../img/mykCement.jpg";
import polad from "../../../img/polad.jpg";
import jkCement from "../../../img/jkCement.jpg";
import relling3 from "../../../img/workthree.jpg";
import { NavLink } from "react-router-dom";
import cement from "../../../img/cement.mp4"
const Blog = () => {
    return (
        <>
            <Navbar />

        <div className="blog">
        <div className="container  mb-5 ourProject">
          <div className="row content">
            <div className="col-md-8">
              <img src={jkCement} className="img-fluid"  alt />
            </div>
            <div className="col-md-4  title-section">
              <div className="d-flex my-auto">
                <h1 className="  blogviewh5  ">
                  Build a Website in <br />
                  minute with
                  <br /> Adobe <br />
                  Templates
                </h1>
              </div>
              <NavLink to="/blogview"><button
                type="button"
                class="btn btn-lg btn-outline-dark px-5 mt-5"
              >
                <h2 className="display-5">Read More</h2>
              </button></NavLink>
            </div>
            
          </div>
        </div>

        <div className="container mt-5 mb-5 ourProject">
          <div className="row content">
            <div className="col-md-8">
              <img src={mykCement} className="img-fluid" width="660" height="560" alt />
            </div>
            <div className="col-md-4 ">
              <div className="d-flex my-auto">
                <h1 className="  blogviewh5  ">
                  Build a Website in <br />
                  minute with
                  <br /> Adobe <br />
                  Templates
                </h1>
              </div>
               <NavLink to="/blogview"><button
                type="button"
                class="btn btn-lg btn-outline-dark px-5 mt-5"
              >
                <h2 className="display-5">Read More</h2>
              </button></NavLink>
            </div>
          </div>
        </div>

        <div className="container mt-5 mb-5 ourProject">
          <div className="row content">
            <div className="col-md-8">
              <img src={jkCement} className="img-fluid" width="660" height="560" alt />
            </div>
            <div className="col-md-4 ">
              <div className="d-flex my-auto">
                <h1 className="  blogviewh5  ">
                  Build a Website in <br />
                  minute with
                  <br /> Adobe <br />
                  Templates
                </h1>
              </div>

               <NavLink to="/blogview"><button
                type="button"
                class="btn btn-lg btn-outline-dark px-5 mt-5"
              >
                <h2 className="display-5">Read More</h2>
              </button></NavLink>
            </div>
          </div>
        </div>


        <div className="container mt-5 mb-5 ourProject">
          <div className="row content">
          <div className="col-md-8">
              <img src={polad} className="img-fluid" width="600" height="500" alt />
            </div>
            <div className="col-md-4">
              <div className="d-flex my-auto">
                <h1 className="blogviewh5">
                  Build a Website in <br />
                  minute with
                  <br /> Adobe Templates 
                  
                </h1>
              </div>

               <NavLink to="/blogview"><button
                type="button"
                class="btn btn-lg btn-outline-dark px-5 mt-5"
              >
                <h2 className="display-5">Read More</h2>
              </button></NavLink>
            </div>
          </div>
        </div>

      </div>
            <Footer />
        </>
    )
}

export default Blog
