import React, { useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { ImFacebook, ImInstagram, ImTwitter, ImWhatsapp } from "react-icons/im";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from "../../img/logo.jpg";
import { NavLink } from "react-router-dom";

const Navbar = () => {
    const [showMediaIcons, setShowMediaIcons] = useState(false);
    const [navbar, setNavbar] = useState(false);
    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };
    window.addEventListener("scroll", changeBackground);
    return (
        <>
            <nav className="position-sticky fixed-top bg-white">
                {/* 1st logo part  */}
                <div className="main-nav">
                    <div className="logo">
                        <img src={logo} className="img-fluid" />
                    </div>

                    {/* 2nd menu part  */}
                    <div
                        className={
                            showMediaIcons
                                ? "menu-link mobile-menu-link"
                                : "menu-link"
                        }
                    >
                        <ul>
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/about">about us</NavLink>
                            </li>
                            <li>
                                <NavLink to="/ourwork">Our works</NavLink>
                            </li>
                            <li>
                                <NavLink to="/gallery">Gallery</NavLink>
                            </li>
                            <li>
                                <NavLink to="/blog">Blog</NavLink>
                            </li>
                            <li>
                                <NavLink to="/faq">F.A.Q</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contact">contact</NavLink>
                            </li>
                        </ul>
                    </div>

                    {/* 3rd social media links */}
                    <div className="social-media">
                        <ul className="social-media-desktop">
                            <li>
                                <div className="social-media-center">
                                    <a
                                        href="https://www.facebook.com/tultechrollingshutters/"
                                        target="_thapa"
                                    >
                                        <ImFacebook
                                            className="facebook"
                                            color="#ffffff"
                                        />
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className="social-media-center">
                                    <Link
                                        to="https://www.facebook.com/tultechrollingshutters/"
                                        target="_thapa"
                                    >
                                        <ImTwitter
                                            className="facebook"
                                            color="#ffffff"
                                        />
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className="social-media-center">
                                    <Link
                                        to="https://www.youtube.com/channel/UCwfaAHy4zQUb2APNOGXUCCA"
                                        target="_thapa"
                                    >
                                        <ImInstagram
                                            className="facebook"
                                            color="#ffffff"
                                        />
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className="social-media-center">
                                    <Link
                                        to="https://www.youtube.com/channel/UCwfaAHy4zQUb2APNOGXUCCA"
                                        target="_thapa"
                                    >
                                        <ImWhatsapp
                                            className="facebook"
                                            color="#ffffff"
                                        />
                                    </Link>
                                </div>
                            </li>
                        </ul>

                        {/* hamburget menu start  */}
                        <div className="hamburger-menu">
                            <Link
                                to="#"
                                onClick={() =>
                                    setShowMediaIcons(!showMediaIcons)
                                }
                            >
                                <GiHamburgerMenu />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="nav-header">
                    <h5 className="nav-header-item">
                        <a href="#" className="text-light">
                            Rolling shutters
                        </a>{" "}
                        |{" "}
                        <a href="#" className="text-light">
                            Domestic grain storage
                        </a>{" "}
                        |{" "}
                        <a href="#" className="text-light">
                            SS Roiling
                        </a>{" "}
                        |{" "}
                        <a href="#" className="text-light">
                            Kitchen
                        </a>
                        <a href="#" className="text-light">
                            trolley
                        </a>{" "}
                        |{" "}
                        <a href="#" className="text-light">
                            Cement
                        </a>{" "}
                        |{" "}
                        <a href="#" className="text-light">
                            steel
                        </a>{" "}
                        |{" "}
                        <a href="#" className="text-light">
                            Waterproofng Consultant
                        </a>{" "}
                        |
                    </h5>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
