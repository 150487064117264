import React from "react";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import "./contact.css";

const Contact = () => {
  return (
    <>
      <Navbar />

      <section id="contact" className="contact">
        <div className="d-flex justify-content-center align-items-center mb-5 maps">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1142.6329059991576!2d74.9400135777826!3d17.434761553824877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc15560870a6a73%3A0xea50277c6b8834d3!2sTool%20Tech!5e0!3m2!1sen!2sin!4v1639214726516!5m2!1sen!2sin"
            width="1100"
            height="300"
            style={{ border: "none" }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>

        <div>
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Contact Us</h2>
            </div>
            <div className="row mt-5">
              <div className="col-lg-4" data-aos="fade-right">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt" />
                    <h4>Location:</h4>
                    <p>Tool Tech, CWMR+W7R, Atpadi, Maharashtra 415301</p>
                  </div>
                  <div className="email">
                    <i className="bi bi-envelope" />
                    <h4>Email:</h4>
                    <p>
                      {" "}
                      <a href="Contact@tultechindia.com" className="text-dark">
                        Contact@tultechindia.com
                      </a>
                    </p>
                  </div>
                  <div className="phone">
                    <i className="bi bi-phone" />
                    <h4>Call:</h4>
                    <p>094039 43992</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 mt-5 mt-lg-0" data-aos="fade-left">
                <form
                  action="forms/contact.php"
                  method="post"
                  role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      rows={5}
                      placeholder="Message"
                      required
                      defaultValue={""}
                    />
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message" />
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center ">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
